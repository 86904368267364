import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/user-withdrawal',
  component: Layout,
  redirect: '/user-withdrawal/list',
  name: 'UserWithdrawal',
  meta: {
    title: '用户提现',
    icon: 'i-ri:user-shared-line',
    sort: 7,
  },
  children: [
    {
      path: 'list',
      name: 'UserWithdrawalList',
      component: () => import('@/views/userWithdrawal/index.vue'),
      meta: {
        title: '用户提现',
        menu: false,
        breadcrumb: false,
        activeMenu: '/user-withdrawal',
      },
    },
  ],
}

export default routes
