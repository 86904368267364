import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/giftManagement',
  component: Layout,
  name: 'giftManagement',
  redirect: '/giftManagement/index',
  meta: {
    title: '礼物管理',
    icon: 'material-symbols:activity-zone',
    sort: 3,
  },
  children: [
    {
      path: 'index',
      name: 'GiftManagement',
      component: () => import('@/views/giftManagement/index.vue'),
      meta: {
        title: '礼物管理',
        icon: 'material-symbols:featured-seasonal-and-gifts-rounded',
        activeMenu: '/giftManagement',
      },
    },
    {
      path: 'record',
      name: 'Record',
      component: () => import('@/views/giftManagement/giftRecord.vue'),
      meta: {
        title: '送礼记录',
        icon: 'material-symbols:featured-play-list-outline',
        activeMenu: '/giftManagement',
      },
    },
  ],
}

export default routes
