import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/dynamicReview',
  component: Layout,
  name: 'dynamicReview',
  redirect: '/dynamicReview/manager',
  meta: {
    title: '动态审核',
    icon: 'i-ri:chat-follow-up-fill',
    sort: 6,
  },
  children: [
    {
      path: 'manager',
      name: 'DynamicReviewManger',
      component: () => import('@/views/dynamicReview/index.vue'),
      meta: {
        title: '动态审核',
        menu: false,
        breadcrumb: false,
        activeMenu: '/dynamicReview',
      },
    },
  ],
}

export default routes
