import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/advertisingSpaceManagement',
  component: Layout,
  name: 'advertisingSpaceManagement',
  redirect: '/advertisingSpaceManagement/index',
  meta: {
    title: '广告位置管理',
    icon: 'material-symbols:aspect-ratio-sharp',
    sort: 5,
  },
  children: [
    {
      path: 'index',
      name: 'AdvertisingSpaceManagement',
      component: () => import('@/views/advertisingSpaceManagement/index.vue'),
      meta: {
        title: '广告位置管理',
        menu: false,
        breadcrumb: false,
        activeMenu: '/advertisingSpaceManagement',
      },
    },
  ],
}

export default routes
