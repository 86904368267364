<route lang="yaml">
name: home
meta:
  title: 主页
  icon: ant-design:home-twotone
</route>

<script setup lang="ts">
onMounted(() => {
  window.location.href = '/pbb-pc-anchorcircle/lugHome/index'
})
</script>

<template>
  <div />
</template>
