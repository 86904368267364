import { defaultsDeep } from 'lodash-es'
import type { RecursiveRequired, Settings } from '#/global'
import settingsDefault from '@/settings.default'

const globalSettings: Settings.all = {
  menu: {
    enableSubMenuCollapseButton: true,
    enableHotkeys: true,
    mode: 'single',
  },
  app: {
    enablePermission: true,
  },
  tabbar: {
    enable: true,
    enableIcon: true,
    enableHotkeys: true,
  },
}

export default defaultsDeep(globalSettings, settingsDefault) as RecursiveRequired<Settings.all>
