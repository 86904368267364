import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/share-config',
  component: Layout,
  redirect: '/share-config/list',
  name: 'ShareConfig',
  meta: {
    title: '分享配置',
    icon: 'i-mdi:share-variant-outline',
    sort: 9,
  },
  children: [
    {
      path: 'list',
      name: 'ShareConfigList',
      component: () => import('@/views/shareConfig/index.vue'),
      meta: {
        title: '分享配置',
        menu: false,
        breadcrumb: false,
        activeMenu: '/share-config',
      },
    },
  ],
}

export default routes
