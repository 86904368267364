import type { App } from 'vue'
import { permissionsAll } from '@/api/modules/userManage'

const permissionList = ref(window.top?.RingPermission?.permissionList || [])

// 如果没有从顶部拿到权限列表 这里就再请求一次
if (permissionList.value && !permissionList.value.length) {
  permissionsAll().then((res) => {
    if (res.code === 200) {
      if (window.top?.RingPermission) {
        window.top.RingPermission.permissionList = res.data
      }
      else if (window.top) {
        window.top.RingPermission = Object.assign({}, window.top.RingPermission, { permissionList: res.data })
      }
      permissionList.value = res.data
    }
  })
}

export default function directive(app: App) {
  // 注册 v-auth 和 v-auth-all 指令
  app.directive('auth', {
    mounted: (el, binding) => {
      if (!useAuth().auth(binding.value)) {
        el.style.display = 'none'
      }
      else {
        el.style.display = ''
      }
      const permissionListWatch = watch(permissionList, () => {
        // 等待权限列表更新完成再执行
        if (!useAuth().auth(binding.value)) {
          el.style.display = 'none'
        }
        else {
          el.style.display = ''
        }
        // 监听一次后停止
        permissionListWatch()
      })
    },
  })
  app.directive('auth-all', {
    mounted: (el, binding) => {
      if (!useAuth().authAll(binding.value)) {
        el.remove()
      }
    },
  })
}
