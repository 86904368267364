import api from '../index'
import type { IUserDetailData, IUserJuniorsParams, IUserLogParams, IUserManagerListParams, IUserSetIdentity, IUserStatisticsData } from '../types/userManage'
import type { IRes } from '@/api/types'

// 用户列表
export function userList(data: Partial<IUserManagerListParams>) {
  return api.post('/tiezi/back/user/managePage', data)
}

// 用户数据统计
export function userStatistics(data: Partial<IUserManagerListParams>): Promise<IRes<IUserStatisticsData>> {
  return api.post('/tiezi/back/user/managePageStat', data)
}

// 删除用户
export function deleteUser(userId: string) {
  return api.post(`/tiezi/user/del?userId=${userId}`)
}

// 启用|禁用用户
export function enableUser(userId: string) {
  return api.post(`/tiezi/back/user/kickOut?userId=${userId}`)
}

// 设置代理
export function userSetIdentity(data: Partial<IUserSetIdentity>) {
  return api.post('/tiezi/back/user/setIdentity', data)
}

// 获取用户权限
export function permissionsAll(): Promise<IRes<string[]>> {
  return api.get('/resource/resource/permissions/all', { params: { sysType: 2 }, baseURL: import.meta.env.VITE_APP_API_BASEURL_TOP })
}

// 获取用户信息
export function userDetail(userId: string): Promise<IRes<IUserDetailData>> {
  return api.get(`/tiezi/back/user/detail?userId=${userId}`)
}

// 用户下级列表
export function userJuniors(data: Partial<IUserJuniorsParams>) {
  return api.post('/tiezi/back/user/juniors', data)
}

// 用户日志列表
export function userLogList(data: Partial<IUserLogParams>) {
  return api.post('/tiezi/log/page', data)
}

// 清除邀请人
export function clearInvitor(data: { id: string }) {
  return api.post('/tiezi/back/user/invitor/clear', data)
}

// 换绑邀请人
export function changeInvitor(data: { id: string, invitorId: string }) {
  return api.post('/tiezi/back/user/invitor/change', data)
}
