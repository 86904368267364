import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/orderVerification',
  component: Layout,
  name: 'orderVerification',
  redirect: '/orderVerification/index',
  meta: {
    title: '订单管理',
    icon: 'material-symbols:activity-zone',
    sort: 3,
  },
  children: [
    {
      path: 'index',
      name: 'OrderVerification',
      component: () => import('@/views/orderVerification/index.vue'),
      meta: {
        title: '订单管理',
        icon: 'material-symbols:list-alt-check-outline-rounded',
        activeMenu: '/orderVerification',
      },
    },
  ],
}

export default routes
