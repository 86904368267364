import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/recharge',
  component: Layout,
  redirect: '/recharge/manage',
  name: 'Recharge',
  meta: {
    title: '充值管理',
    icon: 'i-ri:database-2-line',
    sort: 2,
  },
  children: [
    {
      path: 'manage',
      name: 'RechargeManage',
      component: () => import('@/views/recharge/index.vue'),
      meta: {
        title: '充值管理',
        menu: false,
        breadcrumb: false,
        activeMenu: '/recharge',
      },
    },
  ],
}

export default routes
