import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/lugConfig',
  component: Layout,
  name: 'lugConfig',
  redirect: '/lugConfig/config',
  meta: {
    title: '扩圈配置',
    icon: 'i-material-symbols:blood-pressure-rounded',
    sort: 11,
  },
  children: [
    {
      path: 'config',
      name: 'LugConfigIndex',
      component: () => import('@/views/lugConfig/index.vue'),
      meta: {
        title: '扩圈配置',
        menu: false,
        breadcrumb: false,
        activeMenu: '/lugConfig',
      },
    },
  ],
}

export default routes
