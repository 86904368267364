import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/comment-examine',
  component: Layout,
  name: 'commentExamine',
  redirect: '/comment-examine/index',
  meta: {
    title: '评论审核',
    icon: 'i-ri:chat-follow-up-fill',
    sort: 9,
  },
  children: [
    {
      path: 'index',
      name: 'CommentExamine',
      component: () => import('@/views/commentExamine/index.vue'),
      meta: {
        title: '动态审核',
        menu: false,
        breadcrumb: false,
        activeMenu: '/comment-examine',
      },
    },
  ],
}

export default routes
