import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/user-recharge',
  component: Layout,
  redirect: '/user-recharge/index',
  name: 'UserRecharge',
  meta: {
    title: '用户充值',
    icon: 'i-heroicons-solid:menu-alt-3',
    sort: 4,
  },
  children: [
    {
      path: 'index',
      name: 'UserRechargeIndex',
      component: () => import('@/views/userRecharge/index.vue'),
      meta: {
        title: '用户充值',
        menu: false,
        breadcrumb: false,
        activeMenu: '/user-recharge',
      },
    },
  ],
}

export default routes
