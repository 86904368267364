import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/cardCoupons',
  component: Layout,
  name: 'cardCoupons',
  redirect: '/cardCoupons/index',
  meta: {
    title: '卡券',
    icon: 'mdi-light:content-save',
    sort: 5,
  },
  children: [
    {
      path: 'index',
      name: 'CardCouponsManager',
      component: () => import('@/views/cardCoupons/index.vue'),
      meta: {
        title: '卡券设置',
        icon: 'material-symbols:auto-awesome-motion-sharp',
        activeMenu: '/cardCoupons',
      },
    },
    {
      path: 'order',
      name: 'CardCouponsOrder',
      component: () => import('@/views/cardCoupons/order.vue'),
      meta: {
        title: '卡券订单',
        icon: 'material-symbols:aod-tablet-outline',
        activeMenu: '/cardCoupons',
      },
    },
  ],
}

export default routes
