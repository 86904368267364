import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/dict',
  component: Layout,
  redirect: '/dict/manage',
  name: 'Dict',
  meta: {
    title: '字典管理',
    icon: 'i-tdesign:user-avatar',
    sort: 1,
  },
  children: [
    {
      path: 'manage',
      name: 'DictManage',
      component: () => import('@/views/dictionary/index.vue'),
      meta: {
        title: '字典管理',
        menu: false,
        breadcrumb: false,
        activeMenu: '/dict',
      },
    },
    {
      path: 'childDict',
      name: 'ChildDict',
      component: () => import('@/views/dictionary/childDict.vue'),
      meta: {
        title: '字典详情',
        menu: false,
        breadcrumb: false,
        activeMenu: '/dict',
      },
    },
  ],
}

export default routes
