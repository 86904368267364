import useSettingsStore from '@/store/modules/settings'

export default function useAuth() {
  function hasPermission(permission: string) {
    const settingsStore = useSettingsStore()
    const permissionList = window.top?.RingPermission?.permissionList || []
    if (settingsStore.settings.app.enablePermission) {
      // console.log('开启来了', permissionList)

      // console.log('开启来了', permission, permissionList.includes(permission))
      return permissionList.includes(permission)
    }
    else {
      return true
    }
  }

  function auth(value: string | string[]) {
    let auth

    if (typeof value === 'string') {
      auth = value !== '' ? hasPermission(value) : true
    }
    else {
      auth = value.length > 0 ? value.some(item => hasPermission(item)) : true
    }
    return auth
  }

  function authAll(value: string[]) {
    return value.length > 0 ? value.every(item => hasPermission(item)) : true
  }

  return {
    auth,
    authAll,
  }
}
