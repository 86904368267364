import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/sharingOfficerOrder',
  component: Layout,
  name: 'sharingOfficerOrder',
  redirect: '/sharingOfficerOrder/index',
  meta: {
    title: '订单管理',
    icon: 'material-symbols:lists',
    sort: 3,
  },
  children: [
    {
      path: 'index',
      name: 'SharingOfficerOrder',
      component: () => import('@/views/sharingOfficerOrder/index.vue'),
      meta: {
        title: '订单管理',
        icon: 'material-symbols:list-alt-rounded',
        activeMenu: '/sharingOfficerOrder',
      },
    },
  ],
}

export default routes
