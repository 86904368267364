import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/pointsRecharge',
  component: Layout,
  name: 'pointsRecharge',
  redirect: '/pointsRecharge/index',
  meta: {
    title: '积分充值',
    icon: 'material-symbols:activity-zone',
    sort: 3,
  },
  children: [
    {
      path: 'index',
      name: 'PointsRecharge',
      component: () => import('@/views/pointsRecharge/index.vue'),
      meta: {
        title: '积分充值',
        icon: 'material-symbols:money-bag',
        activeMenu: '/pointsRecharge',
      },
    },
  ],
}

export default routes
