import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/activity',
  component: Layout,
  name: 'activity',
  redirect: '/activity/index',
  meta: {
    title: '活动管理',
    icon: 'material-symbols:activity-zone',
    sort: 3,
  },
  children: [
    {
      path: 'index',
      name: 'Activity',
      component: () => import('@/views/activity/index.vue'),
      meta: {
        title: '活动列表',
        icon: 'material-symbols:checklist',
        activeMenu: '/activity',
      },
    },
    {
      path: 'registrationRecord',
      name: 'RegistrationRecord',
      component: () => import('@/views/activity/registrationRecord/index.vue'),
      meta: {
        title: '报名记录',
        icon: 'material-symbols:assignment-add',
        activeMenu: '/activity',
      },
    },
    {
      path: 'type',
      name: 'ActiveType',
      component: () => import('@/views/activity/type/index.vue'),
      meta: {
        title: '活动类型',
        icon: 'material-symbols:type-specimen',
        activeMenu: '/activity',
      },
    },
    // {
    //   path: 'ongoing',
    //   name: 'Ongoing',
    //   component: () => import('@/views/activity/ongoing/index.vue'),
    //   meta: {
    //     title: '进行中的活动',
    //     icon: 'material-symbols:checklist',
    //     activeMenu: '/activity',
    //   },
    // },
    // {
    //   path: 'signRrecord',
    //   name: 'SignRrecord',
    //   component: () => import('@/views/activity/signRrecord/index.vue'),
    //   meta: {
    //     title: '签到记录',
    //     icon: 'material-symbols:checklist',
    //     activeMenu: '/activity',
    //   },
    // },
    // {
    //   path: 'album',
    //   name: 'ActivityAlbum',
    //   component: () => import('@/views/activity/album/index.vue'),
    //   meta: {
    //     title: '活动相册',
    //     icon: 'material-symbols:checklist',
    //     activeMenu: '/activity',
    //   },
    // },
  ],
}

export default routes
